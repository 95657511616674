// HEADER MASQUÉ PAR DÉFAUT S'AFFICHE AU SCROLL
$(window).scroll(function() {
    var value = jQuery(this).scrollTop();
    if ( value > 100 ) {
        $("#remonte").addClass('active');
        $("#mainNav").addClass('navbar-shrink');
    } else {
        $("#remonte").removeClass('active');
        $("#mainNav").addClass('navbar-shrink');
    }
});

$('.remonte').on('click',function (event) {
    event.preventDefault();
    $('html, body').animate({
        scrollTop: 0
    }, 500);
});

$(function() {
        
    wow = new WOW(
      {
        animateClass: 'animated',
        offset:       100,
        callback:     function(box) {
          console.log("WOW: animating <" + box.tagName.toLowerCase() + ">")
        }
      }
    );
    wow.init();
    $('[data-toggle="tooltip"]').tooltip();
    
    $('[data-toggle="popover"]').popover();
    
    $.ajaxSetup({
        headers: { 'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content') }
    });
    
    $(".lightgallery").lightGallery({
        selector: '.selector',
        videojs: true,
        thumbnail:true
    });
    
    $('table').each(function(){
       $(this).find('td').css('padding',$(this).attr('cellpadding')+'px');
       $(this).find('td').css('margin',$(this).attr('cellspacing')+'px');
    });

    $('[data-link]').on('click', function(e) {
        e.preventDefault();
        if ($(this).data('target')=="new") {
            var win = window.open($(this).data('link'), '_blank');
              win.focus();
        }
        else { location.href = $(this).data('link'); }
        
    });
    
    $('.same-height').matchHeight();
    $('#hideseeksearch').hideseek({
        list: '.hideseek-list',
        highlight: true,
		ignore_accents: true
    });
    
    $('.grid').colcade({
        columns: '.grid-col',
        items: '.grid-item'
    });
    
    $( window ).resize(function() {
        if ($('body').height() < $(window).height()) {$('#footer-new').addClass('footer-bottom');}
    });
    if ($('body').height() < $(window).height()) {$('#footer-new').addClass('footer-bottom');}
    
});